import {toast} from '@ecosio/components';
import moment from 'moment';
import {STANDARD_TOAST_DURATION} from '../../helpers/constants';
import {
  DRAFTS_DELAY_ENABLED,
  DRAFTS_DELAY_SCHEDULED,
  DRAFTS_DELAY_TIME,
  MOMENT_ONLY_DATE_FORMAT,
  MOMENT_DATE_AND_TIME_FORMAT,
} from '../../constants';

const shouldRenderDrafts = (userConfig = {}) => {
  if (
    userConfig?.draftsAndDelaysConfiguration
      ?.enableDelayedDocumentSendingAndDrafts !== true
  ) {
    return false;
  }

  const webEdiSettingsFormFields =
    userConfig?.webEdiSettingsConfiguration?.formFields || {};

  const neededFields = [
    DRAFTS_DELAY_ENABLED,
    DRAFTS_DELAY_TIME,
    DRAFTS_DELAY_SCHEDULED,
  ];

  const allDelayFieldsConfigExists = neededFields.every((key) =>
    Object.keys(webEdiSettingsFormFields).includes(key)
  );

  /**
   * if fields are not configured in moduleConfig
   * then dont render the drafts
   */
  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (!allDelayFieldsConfigExists) {
    return false;
  }

  return true;
};

const triggerToast = (
  intl,
  toastTitle = 'TITLE',
  toastDescription = 'Description',
  toastType = 'success'
) => {
  toast({
    title: intl.formatMessage({
      id: toastTitle,
    }),
    description: intl.formatMessage({
      id: toastDescription,
    }),
    type: toastType,
    time: STANDARD_TOAST_DURATION,
  });
};

const triggerDefaultErrorToast = (intl) => {
  toast({
    title: intl.formatMessage({
      id: 'GENERAL_DRAFT_FAILURE_TITLE',
    }),
    description: intl.formatMessage({
      id: 'GENERAL_DRAFT_FAILURE_DESCRIPTION',
    }),
    type: 'error',
    time: STANDARD_TOAST_DURATION,
  });
};

const triggerDelayErrorToast = (error, intl) => {
  const responseData = error?.response?.data;

  if (responseData?.errorTranslationKey) {
    triggerToast(
      intl,
      `${responseData?.errorTranslationKey}_TITLE`,
      `${responseData?.errorTranslationKey}_DESCRIPTION`,
      'error'
    );
  } else {
    triggerDefaultErrorToast(intl);
  }
};

const convertUTCdateTimeToLocalDateTime = (utcDate, locale = 'en') => {
  if (!utcDate) {
    return null;
  }

  //convert utc dateTime to browser local dateTime
  let date = moment.utc(utcDate).local().locale(locale);

  if (date.hours() === 0 && date.minutes() === 0 && date.seconds() === 0) {
    date = date.format(MOMENT_ONLY_DATE_FORMAT);
  } else {
    date = date.format(MOMENT_DATE_AND_TIME_FORMAT);
  }
  return date;
};

const isSendingDelayFromWebEdiSettingsEnabled = (draftSettings = {}) => {
  if (draftSettings?.shouldRenderDrafts === false) {
    return false;
  }

  const webediSettings = draftSettings?.webediSettings || [];
  const delayIsEnabledObject = webediSettings.find(
    (setting) => setting?.type === DRAFTS_DELAY_ENABLED
  );

  return delayIsEnabledObject?.value === 'true';
};

export {
  triggerDelayErrorToast,
  shouldRenderDrafts,
  convertUTCdateTimeToLocalDateTime,
  isSendingDelayFromWebEdiSettingsEnabled,
};
